body {
  margin: 0;
}

.top-container {
  width: 100vw;
  max-width: 900px;
  height: 100vh;
  max-height: 1000px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h1 {
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 157.692% */
  letter-spacing: 1.04px;
}

h2 {
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 157.692% */
  letter-spacing: 1.04px;
}

@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

/* include a separate type of fadeOut for components that are not at full opacity */
@keyframes fadeOut2 {  
  0% { opacity: 0.85 }
  100% { opacity: 0 }
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeIn2 {
  0% { opacity: 0 }
  100% { opacity: 0.85 }
}

@keyframes videoBlur {
  0% { filter: blur(0px) }
  100% { filter: blur(5xpx) }
}

.logo {
  /* from figa */
  width: 290px;
  height: 57px;
  flex-shrink: 0;
  margin-top: 0px;
  margin-bottom: -6px;
  
}

.soundwave {
  /* from figa */
  width: 600px;
  height: 200px;
  flex-shrink: 0;
  margin-top: 40px;
}

.sceneImg {
  width: 774px;
  height: 363px;
  flex-shrink: 0;
  margin-bottom: 6px;
}

.sceneButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 3px;
}

.aid-container {
  position: absolute;
  top: 3%;
  right: -45px;
  width: 120px;
  opacity: 0.85;
  /* animation: fadeIn2 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: forwards; */
  z-index: 2;
}

.single-button-container {
  position: absolute;
  top: 4px;
  right: 16px;
  z-index: 2;
}

.pauseButtonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
}

.pauseButton {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  background: white;
  color: #0E223F;
  font-size: 24px;
  margin-top: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pauseButton img {
  height: 32px;
}

.home-button {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background: white;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.home-button img {
  height: 35px;
}

.question-button {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background: white;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.question-button img {
  height: 30px;
}

.sceneButton {
  width: 245px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background: #F8F6F3;
  color: #0E223F;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 9px;
  box-shadow: 2px 5px 10px lightgrey;
  border: none;
  cursor: pointer;
}

.continueButton {
  width: 245px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background:#0E223F;
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 60px;
  cursor: pointer;
}

.sceneButtonSelected {
  width: 245px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background: #2D415C;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 9px;
  cursor: pointer;
  box-shadow: 2px 5px 10px lightgrey;
}

.sceneButtonDisabled {
  width: 245px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background: #F8F6F3;
  color: #0E223F;
  opacity: 0.4;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 9px;
  box-shadow: 2px 5px 10px lightgrey;
  border: none;
  cursor: pointer;
}

.submitButton {
  width: 240px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background: #117E9F;
  color: #FFF;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 9px;
  box-shadow: 1px 2px 4x lightgrey;
  border: none;
  cursor: pointer;
}

.submitButtonDisabled {
  width: 240px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 6px;
  border-right: 0.5px solid #2D415C;
  border-bottom: 0.5px solid #2D415C;
  background: #117E9F;
  color: #FFF;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 9px;
  box-shadow: 1px 2px 4x lightgrey;
  border: none;
  cursor: pointer;
  opacity: 0.3;
}


.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: -30px;
  /* height: 15vh; */
}

.header > h1 {
  margin: 5px 0 0 0;
}

.simulator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 34px auto 0;
  /* max-height: 80%; */
}

.videoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;    
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 5px;
}

.video-player-container {
  /* height: 100%; */
  height: 477px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.lead-capture-container {
  position: absolute;
  height: 100%;
  background: #2C395B;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.header-form-container {
  margin-top: 40px;
  margin-bottom: 0px;
  height: 100%;
  background: #2C395B;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.header-sales-form-container {
  margin-top: 120px;
  margin-bottom: 0px;
  height: 100%;
  background: #2C395B;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;  
}


.header-form-text {
  color: #FFF;
  font-size: 22px;
  width: 405px;
  text-align: center;
  font-family: 'Georgia';
}

.form-container {
  background: #2C395B;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 140px;
}

.sales-form-container {
  background: #2C395B;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 220px;
}

.form-input-container {
  position: relative;
}

.formInput {
  width: 400px;
  height: 40px;
  background: #FFF;
  border-radius: 6px;
  margin-bottom: 25px;
  padding-left: 5px;
  font-size: 18px;
}

.formInputError {
  width: 400px;
  height: 36px;
  background: #FFF;
  border-radius: 6px;
  border-color: red;
  border-width: 2px;
  margin-bottom: 30px;
  padding-left: 5px;
  font-size: 18px;
}

.formLabel {
  width: 400px;
  color: #FFF;
  margin-top: 15px;
  margin-bottom: -5px;
}

.formError {
  width: 400px;
  color: white;
  position: absolute;
  left: 3px;
  bottom: 9px;
}

.submit-form-container {
  background: #2C395B;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 355px;
  position: absolute; 
}

.submit-sales-form-container {
  background: #2C395B;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 290px;
  position: absolute; 
}

.footer-container {
  margin-top: 320px;
  margin-bottom: 0px;
  height: 100%;
  background: #2C395B;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;  

}

.footer-sales-container {
  margin-top: 220px;
  margin-bottom: 0px;
  height: 100%;
  background: #2C395B;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.footer-form-text {
  color: #FFF;
  font-size: 11px;
  width: 405px;
  text-align: center;
  font-family: 'Georgia';
}

.link-text {
  color: #FFF;
  font-size: 11px;
  width: 405px;
  text-align: center;
  font-family: 'Georgia';
}

.video-blur-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-player {
  overflow: hidden;
  transform: scale(1.08);
}

.video-player-blur-animation {
  animation: videoBlur 2s;
  animation-fill-mode: forwards;
}

.tooltip-container {
  position: absolute;
  top: 3%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 90px;
  font-family: sans-serif;
  font-size: 24px;
  box-sizing: border-box;
  max-width: 50%;
  opacity: 0;
  animation: fadeIn2 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  height: 172px;
}

.tooltip-container-fade-out {
  opacity: 0.85;
  animation: fadeOut2 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.tooltip-container p {
  max-width: 70%;
  padding-right: 15px;
}

.tooltip-container img {
  height: 50px;
  opacity: 0.85;
  margin-top: 75px;
}

.aided-indicator {
  position: absolute;
  left: 0; 
  top: 44%;
  height: 140px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.aided-indicator p {
  transform: rotate(270deg);
  color: white;
  white-space: nowrap;
  font-family: sans-serif;
}

.click-scene-info {
  position: absolute;
  bottom: 0;
  background: white;
  opacity: 0.85;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.click-scene-info p {
  font-family: sans-serif;
  font-size: 20px;
  margin-right: 10px;
}

.click-scene-info img {
  transform: rotate(90deg);
  object-fit: contain;
  height: 40px;
}

.click-scene-info-fade-in {
  opacity: 0;
  animation: fadeIn2 1.5s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

.help-text-container {
  position: absolute;
  top: 3%;
  left: 15px; 
  width: 100%;
  max-width: 75%;
  background: white;
  border-radius: 10px;
  height: 170px;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-size: 24px;
  overflow: hidden;
}

.help-text-fade-in {
  opacity: 0;
  animation: fadeIn2 1.5s ease-out;
  animation-fill-mode: forwards;
}

.side-text {
  background: #307094;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-text p {
  font-size: 20px;
  transform: rotate(270deg);
  color: white;
  white-space: nowrap;
}

.help-text-container > p {
  padding: 0 25px;
}

.instructions-container {
  position: absolute;
  bottom: 3%;
  background: white;
  padding: 0px 20px;
  border-radius: 10px;
  width: 94%;
  max-width: 900px;
  box-sizing: border-box;
  opacity: 0.85;
}

.instructions-fade-out {
  animation: fadeOut2 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.instructions-fade-out-delayed {
  animation: fadeOut2 1.5s ease-in-out;
  animation-delay: 10s;
  animation-fill-mode: forwards;
}

.instructions-text-container {
  font-family: sans-serif;
  font-size: 20px;
  line-height: 1.9rem;
}

.instructions-text-container p:first-of-type {
  margin-bottom: 0px;
}

/* for iPad screen sizes */
@media (max-width: 1024px) {
  .top-container {
    max-width: none;
    max-height: none;
  }

  .header {
    height: 25vh;
    margin-bottom: -20vh;
  }

  /* .header > img {
    height: 6vh;
    object-fit: contain;
  } */

  
  .videoContainer {
    height: 77vh;
    width: 100%;
  }

  .tooltip-container {
    right: 115px;
  }

  .help-text-container {
    left: 24px;
  }

  .video-player-container {
    height: 77vh;
    border-radius: 0px;
  }

  .video-blur-container {
    height: 77vh;
  }

  .video-player {
    margin-left: -40%;
  }

  .default-background {
    margin-left: -80%;
  }

  .aid-container {
    right: -35px;
  }

  .instructions-container {
    max-width: none;
  }

  .sceneButtonContainer {
    width: 100%;
    height: 8vh;
  }

  .sceneButton, .sceneButtonSelected {
    flex-grow: 1;
    width: 30%;
  }

  .sceneButton:first-of-type {
    margin-left: 14px;
  }

  .sceneButton:last-of-type {
    margin-right: 14px;
  }

  .lead-capture-container {
    border-radius: 0px;
  }

  .aided-indicator {
    top: 42%;
  }
}

/* for iPhone screen sizes */
@media (max-width: 480px) {

  .header img {
    height: 4.5vh;
    padding-top: 5px;
    object-fit: contain;
  }

  .header h1 {
    font-size: 20px;
  }

  .videoContainer {
    max-height: 68vh;
  }

  .video-player-container {
    height: 68vh;
    border-radius: 0px;
  }

  .video-blur-container {
    height: 68vh;
  }

  .default-background {
    margin-left: -255%;
    margin-top: -90%;
  }


  /* having to update both for now - JP */

  .formInput {
    width: 300px;
    height: 30px;
  }
  .formInputError {
    width: 300px;
    height: 30px;
  }

  .header-form-container {
    margin-top: 20px;
    margin-bottom: 0px;
    height: 100%;
    background: #2C395B;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-container {
    background: #2C395B;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 120px; 
  }

  .submit-form-container {
    background: #2C395B;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 315px;
    position: absolute; 
  }

  .header-form-text {
    max-width: 240px;
    text-align: left;
    margin-left: -60px;
    font-size: 16px;
  }

  .footer-container {
    margin-top: 290px;
  }

  .footer-form-text {
    width: 320px;
  }

  .sceneButton {
    font-size: 18px;
    line-height: 22px;
  }

  .aid-container {
    right: -47px;
  }

  .tooltip-container {
    right: 87px;
    padding: 10px;
    max-width: 72%;
  }

  .tooltip-container p {
    font-size: 22px;
    padding: 0 0 0 10px;
  }

  .tooltip-container img {
    margin-top: 80px;
  }

  .help-text-container {
    left: 12px;
    max-width: 70%;
  }

  .help-text-container > p {
    font-size: 22px;
  }

  .help-text-container .side-text {
    padding: 0 7px;
  }

  .aided-indicator {
    top: 53%;
  }

  .click-scene-info p {
    font-size: 14px;
  }

  .click-scene-info img {
    height: 30px;
  }
}
